<template>
    <q-page style="min-height: auto !important">
        <loading-alert :display="displayLoading"></loading-alert>
        <ygo-alert
            :display="displayAlert"
            :title="alertTitle"
            :message="alertMessage"
            :type="alertType"
            :buttonName="buttonName"
            @accept="$router.push('/user')"
        ></ygo-alert>
        <div  v-if="pagina_origen != 'life'">
        <!-- DESKTOP SLIDES -->
        <VueSlickCarousel
            :arrows="false"
            :dots="false"
            :autoplay="true"
            :autoplaySpeed="10000"
            class="xs-hide"
        >
            <q-img :src="require('@/assets/Landing/BANNER-03.svg')" />
            <q-img :src="require('@/assets/Landing/BANNER-01.svg')" />
            <q-img :src="require('@/assets/Landing/BANNER-02.svg')" />
        </VueSlickCarousel>
        <!-- /DESKTOP SLIDES -->

        <!-- MOBILE SLIDES -->
        <VueSlickCarousel
            :arrows="false"
            :dots="false"
            :autoplay="true"
            :autoplaySpeed="10000"
            class="xl-hide lg-hide md-hide sm-hide"
        >
            <q-img :src="require('@/assets/Landing/BANNER_mobile-03.svg')" />
            <q-img :src="require('@/assets/Landing/BANNER_mobile-01.svg')" />
            <q-img :src="require('@/assets/Landing/BANNER_mobile-02.svg')" />
        </VueSlickCarousel>
        <!-- /MOBILE SLIDES -->
   
        <div class="row q-pa-md xl-hide lg-hide md-hide sm-hide">
            <div class="text-h6 q-px-lg text-center text-accent q-mb-md">
 
                Afíliate  al programa de lealtad de <br /><strong>Y-GÓ! <br /></strong>Y llevate
                libras <strong>GRATIS</strong> con tu primer pedido.
            </div>
            <q-btn
                label="Conoce más"
                color="primary"
                push
                class="text-bold"
                to="/programa-de-lealtad"
                style="margin: 0 auto"
            />
        </div>
    </div>
        <div class="row form-section">
            <q-space />
            <div class="col-lg-7 col-md-9 col-xs-12 q-px-md">
                <div class="row">
                    <div class="col-lg-4 col-md-5 col-sm-6 col-xs-12 q-py-lg q-px-md">
                        <q-form @submit="registerUser()">
                            <q-card style="border-left: solid 5px #ff5722">
                                <q-card-section>
                                    <div class="text-h5 text-center text-primary q-mb-xs">
                                        REGÍSTRATE
                                    </div>
                                    <q-separator
                                        color="accent"
                                        style="width: 150px; margin: 0 auto; height: 2px"
                                    />
                                    <div class="text-body1 text-center q-mt-md">
                                        ¿Ya tienes cuenta con nosotros? <br />Inicia sesión
                                        <router-link to="/login" class="text-accent text-bold"
                                            >aquí</router-link
                                        >.
                                    </div>
                                </q-card-section>
                                <q-card-section>
                                    <q-input
                                        ref="name"
                                        filled
                                        label="Nombre"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.name"
                                        name="name"
                                        :rules="[val => !!val || 'Este campo es requerido']"
                                    />
                                    <q-input
                                        ref="lastName"
                                        filled
                                        label="Apellido"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.lastName"
                                        name="lastname"
                                        :rules="[val => !!val || 'Este campo es requerido']"
                                    />
                         <!--           
                                    <q-input
                                        ref="email"
                                        filled
                                        label="Correo"
                                        type="email"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.email"
                                        name="email"
                                        :rules="[
                                            val =>
                                                validEmail.test(val) || '<b>Ingrese un correo válido</b>',
                                        ]"
                                    >
                                    </q-input>

                                -->
                                

                                    <q-input
                                        ref="email"
                                        filled
                                        label="Correo"
                                        type="email"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.email"
                                        name="email"
                                        :error="isValidEmail"
                                        hint = "Correo"
                               
                                    >
                                    <template v-slot:error>
                                    <b>Ingrese un correo válido</b>
                                    </template>
                                    </q-input>
        
                   
                     <!---->
                                    <q-input
                                        v-if="showPassword"
                                        ref="password"
                                        filled
                                        label="Contraseña"
                                        type="text"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.password"
                                        :rules="[
                                            val =>
                                                val.length >= 6 ||
                                                'La contraseña debe ser al menos 6 caracteres.',
                                        ]"


                                    >
                                    <template v-slot:append>
                                    <div  style="font-size: .8em; opacity: 0.3;">
                                        <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"   @click="toggleShow" ></i>
                                    </div>
                                    </template>
                                    </q-input>


    
                                  

                                    <q-input
                                        v-else
                                        ref="password"
                                        filled
                                        label="Contraseña"
                                        type="password"
                                        color="accent"
                                        class="q-mb-sm"
                                        v-model="form.password"
                                        :rules="[
                                            val =>
                                                val.length >= 6 ||
                                                'La contraseña debe ser al menos 6 caracteres.',
                                        ]"
                                    >
                                    <template v-slot:append>
                                    <div  style="font-size: .8em; opacity: 0.3;">
                                        <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"   @click="toggleShow" ></i>
                                    </div>
                                    </template>
                                    </q-input>

                                    <q-input
                                        v-if="showPassword"
                                        ref="rePassword"
                                        filled
                                        label="Repetir contraseña"
                                        type="text"
                                        color="accent"
                                        v-model="form.repassword"
                                        :rules="[
                                            val =>
                                                val == form.password ||
                                                'Las contraseñas no coinciden',
                                        ]"
                                    >
                                    <template v-slot:append>
                                    <div  style="font-size: .8em; opacity: 0.3;">
                                    <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"   @click="toggleShow" ></i>
                                    </div>
                                    </template>
                                    </q-input>

                                    <q-input
                                        v-else
                                        ref="rePassword"
                                        filled
                                        label="Repetir contraseña"
                                        type="password"
                                        color="accent"
                                        v-model="form.repassword"
                                        :rules="[
                                            val =>
                                                val == form.password ||
                                                'Las contraseñas no coinciden',
                                        ]"
                                    >
                                    <template v-slot:append>
                                    <div  style="font-size: .8em; opacity: 0.3;">
                                    <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"   @click="toggleShow" size="4rem"></i>
                                    </div>
                                    </template>
                                    </q-input>

                                </q-card-section>
                                <q-card-actions class="q-px-md">
                                    <q-btn
                                        color="accent"
                                        label="Regístrate ya"
                                        class="text-bold full-width"
                                        push
                                        type="submit"
                                    />
                                </q-card-actions>
                                <q-card-section>
                                    <div class="text-body2">
                                        Al hacer clic en
                                        <span class="text-bold">"REGÍSTRATE"</span> acepto los
                                        terminos y condiciones. Leer
                                        <a
                                            href="/terminos-y-condiciones"
                                            target="_blank"
                                            class="text-bold text-accent"
                                            >aquí.</a
                                        >
                                    </div>
                                </q-card-section>
                            </q-card>
                        </q-form>
                    </div>
                    <div class="col-lg-8 col-md-7 col-sm-6 col-xs-12 column flex-center xs-hide">
                        <div class="text-h4 q-px-lg text-center text-white q-mb-lg">
                           
                            <div v-if="pagina_origen == 'life'">  <p>  <img src="https://tamitut.com/PAYA/images/lifeline.png">   </p></div>
                            <div v-else>
                            Afíliate al programa de lealtad de <strong>Y-GÓ! <br /></strong>Y
                            llevate libras <strong>GRATIS</strong> con tu primer pedido.
                            </div>
                        </div>
                        <q-btn v-if="pagina_origen != 'life'"
                            label="Conoce más"
                            color="accent"
                            push
                            class="text-bold"
                            to="/programa-de-lealtad"
                        />
                    </div>
                </div>
            </div>
            <q-space />
        </div>
    </q-page>
</template>

<script>
import * as api from '@/api/api'
import firebase from 'firebase/app'
import 'firebase/auth'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { ref } from 'vue'

export default {
    data() {
        return {
            slide: 1,
            pagina_origen: new URL(location.href).searchParams.get('page'),
            showRegisterForm: false,
            terms: false,
            displayLoading: false,
            confirmationDialog: false,
            displayAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertType: '',
            form: {
                name: '',
                lastName: '',
                email: '',
                contactPhone: '',
                password: '',
                repassword: '',
                absorbedCompany : '',
            },
            strongPass: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            validEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            buttonName: 'Continuar',
            showPassword: false,
            isValid: false,
          //  isValid: computed(() => form.email.value.length <= 3),
          //  password: null,
          //  password: ref(''),
          //  isPwd: ref(true),
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        isAuthenticated() {
            return this.$store.getters.isAuthenticated
        },
        buttonLabel() {
            return (this.showPassword) ? "Hide" : "Show";
        },
        isValidEmail(){
            console.log('el isValid: ' + !this.validEmail.test(this.form.email))
            /*
            if(this.isValid){
                this.isValid = false;
                return false;
            }
            //return true;
            this.isValid = this.validEmail.test(this.form.email);
            */
            if(this.isValid == false){
                console.log('solo esta vez se envia true');
                this.isValid = true;
                return false;
            }
            return !this.validEmail.test(this.form.email);
            
        },
    },
    methods: {
        clear() {
            this.form.name = ''
            this.form.lastName = ''
            this.form.email = ''
            this.form.contactPhone = ''
            this.form.password = ''
            this.form.repassword = ''
        },
        registerFormReset() {
            setTimeout(() => {
                this.$refs.name.resetValidation()
                this.$refs.lastName.resetValidation()
                this.$refs.email.resetValidation()
                this.$refs.password.resetValidation()
                this.$refs.rePassword.resetValidation()
            }, 1)
        },
        getUrlParam(sParam) {
            var sPageURL = window.location.search.substring(1)
            var sURLVariables = sPageURL.split('&')
            for (var i = 0; i < sURLVariables.length; i++) {
                var sParameterName = sURLVariables[i].split('=')
                if (sParameterName[0] == sParam) {
                    return sParameterName[1]
                }
            }
        },
        setMarkerPosition(event) {
            this.form.location = event
        },
        async registerUser() {
            if (
                this.form.name === '' ||
                this.form.lastName === '' ||
                this.form.email === '' ||
                this.form.password === ''
            ) {
                this.alertTitle = 'Error'
                this.alertMessage = 'Debes llenar todos los campos.'
                this.alertType = 'error'
                this.displayAlert = true
                return
            }
            if (this.form.password != this.form.repassword) {
                this.alertTitle = 'Error'
                this.alertMessage = 'Las contraseñas con coinciden.'
                this.alertType = 'error'
                this.displayAlert = true
                return
            }
            if(this.pagina_origen == 'life'){
                this.form.absorbedCompany = 'LifeLine';
            }
            this.displayLoading = true
            try {
                let user = await firebase
                    .auth()
                    .createUserWithEmailAndPassword(this.form.email, this.form.password)
                await api.createuserondatabase({
                    user: user.user,
                })
                await api.updateUserWithInfo({
                    uid: user.user.uid,
                    obj: this.form,
                })
                await api.getUserInformationById({uid: user.user.uid}).then(async response => {
                    await this.$store.commit('SET_USER', response.data.data)
                })

                
                this.displayLoading = false
                this.clear()
                this.registerFormReset()
                this.$router.push('/user')  /// tratamos de saltarnos el notification
                

                /*
                this.displayLoading = false
                this.alertTitle = '¡Bienvenido!'
                this.alertMessage = 'Te has registrado satisfactoriamente. Procede a completar la información de tu perfil'
                this.alertType = 'success'
                this.displayAlert = true
                this.clear()
                this.registerFormReset()
                */


           
               
            } catch (error) {
                console.log(error)
                this.displayLoading = false
                this.alertTitle = 'Error'
                this.alertType = 'error'
                if (error.code === 'auth/email-already-in-use') {
                    this.alertMessage = 'Este correo ya esta en uso registrado'
                }
                if (error.code === 'auth/weak-password') {
                    this.alertMessage = 'La contraseña es demasiado devil, intenta otra'
                }
                if (error.code === 'auth/invalid-email') {
                    this.alertMessage = 'El email no es valido por favor revisalo'
                } else {
                    this.alertMessage = error
                }
                this.displayAlert = true
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
            //console.log('la ruta:',this.$route);
        },

    },
    components: {
        VueSlickCarousel,
    },
    mounted() {
        window.scrollTo(0, 0)
        console.log('la ruta:',this.$router);
        this.datoPagina = new URL(location.href).searchParams.get('page');
        if(this.datoPagina != null)
            console.log('la ruta 2:',this.datoPagina);
        else
            console.log('no hay ruta');    

    },
}
</script>

<style scoped>
.custom-caption {
    text-align: center;
    height: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
