<template>
    <q-layout class="y-go-font">
        <loading-alert :display="displayLoading"></loading-alert>
        <ygo-alert
            :display="displayAlert"
            :title="alertTitle"
            :message="alertMessage"
            :type="alertType"
            @accept="completeRegistrationDialog = true"
        ></ygo-alert>
        <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" >
            <img alt="logo"  src="https://tamitut.com/PAYA/images/lifelinewhite.png"> 
        </div>
        <div v-else>
            <Navbar />
        </div>
        <q-page-container v-if="Object.keys(userInformation).length > 0" class="pattern-bg">
            <q-page>
                <div class="row q-mt-lg">
                    <div class="col desktop-only"></div>
                    <div class="col-lg-7 col-md-9 q-pt-lg">
                        <div class="q-pa-sm">
                            <q-card
                                :class="
                                    userInformation.user.affiliateCardNo != null
                                        ? `q-mb-lg ${returnTier(value).frame}`
                                        : 'q-mb-lg '
                                "
                            >
                                <q-card-section>
                                    <div class="row">
                                        <div
                                            class="col-lg-8 col-md-8 col-sm-8 col-xs-12 column"
                                            v-if="showBusinessNotApproved()"
                                        >
                                            <div class="text-accent">
                                                <span class="text-h5">Bienvenido,</span>
                                                &nbsp;
                                                <span class="text-h4 text-bold">{{
                                                    userName
                                                }}</span>
                                            </div>
                                            <div class="text-h5 q-mt-lg">
                                                Tu peticion de plan business esta en proceso.
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-8 col-md-8 col-sm-8 col-xs-12 column"
                                            v-else
                                        >
                                            <div class="text-accent">
                                                <span class="text-h5">Bienvenido,</span>
                                                &nbsp;
                                                <br v-if="$q.screen.name == 'xs'" />
                                                <span class="text-h4 text-bold">{{
                                                    userName
                                                }}</span>
                                            </div>
                                            <div v-if="userInformation.user.company != undefined && userInformation.user.company != ''" class="text-accent">
                                                <span class="text-h5">Compañia: </span>
                                                &nbsp;
                                                <br v-if="$q.screen.name == 'xs'" />
                                                <span class="text-h4 text-bold">{{
                                                    userInformation.user.company
                                                }}</span>
                                            </div>
                                            <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-h5 text-accent q-mb-md">
                                                Casillero:
                                                <strong>{{  "LQ" + userInformation.user.box.slice(3) }}</strong>
                                            </div>
                                            <div v-else class="text-h5 text-accent q-mb-md">
                                                Casillero:
                                                <strong>{{ userInformation.user.box }}</strong>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-xs-12 q-mb-md">
                                                    <div class="row text-center">
                                                        <div class="col-6">
                                                            <span class="text-bold"
                                                                >Dirección aérea</span
                                                            >
                                                        </div>
                                                        <div class="col-2">
                                                            <q-icon
                                                                name="fas fa-plane-departure text-primary"
                                                                size="sm"
                                                                class="q-mb-sm"
                                                            />
                                                        </div>
                                                        <div class="col-4">
                                                            <q-btn
                                                                icon="fas fa-copy"
                                                                label="Copiar"
                                                                flat
                                                                color="accent"
                                                                size="xs"
                                                                rounded
                                                                class="on-right text-bold"
                                                                @click="
                                                                    copyAddressToClipboard('air')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Nombre:
                                                        <span class="text-bold"
                                                            >{{ userName }}
                                                            LQ
                                                        </span>
                                                    </div>
                                                    <div v-else class="text-caption">
                                                        Nombre:
                                                        <span class="text-bold"
                                                            >{{ userName }}
                                                            YGO
                                                        </span>
                                                    </div>
                                                    <div  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Campo de dirección 1:
                                                        <span class="text-bold"
                                                            >8458 NW 70<sup>th</sup>ST</span
                                                        >
                                                    </div>
                                                    <div  v-else class="text-caption">
                                                        Campo de dirección 1:
                                                        <span class="text-bold"
                                                            >7270 NW 35<sup>th</sup> Terrace</span
                                                        >
                                                    </div>
                                                    <div class="text-caption">
                                                        Campo de dirección 2:
                                                        <span  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-bold">{{
                                                            "STE LQ" + userInformation.user.box.slice(3)
                                                        }}</span>
                                                        <span  v-else class="text-bold">Suite 602 {{
                                                            userInformation.user.box
                                                        }}</span>
                                                    </div>
                                                    <div v-if="userInformation.user.absorbedCompany == 'LifeLine'"  class="text-caption">
                                                        Ciudad:
                                                        <span class="text-bold">Miami</span>
                                                    </div>
                                                    <div v-else  class="text-caption">
                                                        Ciudad:
                                                        <span class="text-bold">Miami</span>
                                                    </div>
                                                    <div class="text-caption">
                                                        Estado:
                                                        <span class="text-bold">Florida</span>
                                                    </div>
                                                    <div class="text-caption">
                                                        País:
                                                        <span class="text-bold"
                                                            >Estados Unidos</span
                                                        >
                                                    </div>
                                                    <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Código postal:
                                                        <span class="text-bold">33166-6215</span>
                                                    </div>
                                                    <div v-else class="text-caption">
                                                        Código postal:
                                                        <span class="text-bold">33122-1352</span>
                                                    </div>
                                                    <div  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Número de telefono:
                                                        <span class="text-bold">305-406-0003</span>
                                                    </div>
                                                    <div  v-else class="text-caption">
                                                        Número de telefono:
                                                        <span class="text-bold">786-628-8807</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xs-12 q-mb-md">
                                                    <div class="row text-center">
                                                        <div class="col-6">
                                                            <span class="text-bold"
                                                                >Dirección maritima</span
                                                            >
                                                        </div>
                                                        <div class="col-2">
                                                            <q-icon
                                                                name="fas fa-ship text-primary"
                                                                size="sm"
                                                                class="q-mb-sm"
                                                            />
                                                        </div>
                                                        <div class="col-4">
                                                            <q-btn
                                                                icon="fas fa-copy"
                                                                label="Copiar"
                                                                flat
                                                                color="accent"
                                                                size="xs"
                                                                rounded
                                                                class="on-right text-bold"
                                                                @click="
                                                                    copyAddressToClipboard('ship')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Nombre:
                                                        <span class="text-bold"
                                                            >{{ userName }} LQ </span
                                                        >
                                                    </div>
                                                    <div  v-else class="text-caption">
                                                        Nombre:
                                                        <span class="text-bold"
                                                            >{{ userName }} YGO</span
                                                        >
                                                    </div>
                                                    <div   v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Campo de dirección 1:
                                                        <span class="text-bold"
                                                            >7854 NW 46<sup>th</sup> ST</span
                                                        >
                                                    </div>
                                                    <div   v-else class="text-caption">
                                                        Campo de dirección 1:
                                                        <span class="text-bold"
                                                            >7854 NW 46<sup>th</sup> Street</span
                                                        >
                                                    </div>
                                                    <div class="text-caption">
                                                        Campo de dirección 2:

                                                        <span  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-bold">{{
                                                            "STE LQ" + userInformation.user.box.slice(3)
                                                        }} </span>
                                                        <span  v-else class="text-bold">Suite {{
                                                            userInformation.user.box
                                                        }}</span>
                                                        <!--
                                                        <span class="text-bold">
                                                            {{ userInformation.user.box }}
                                                            OCEAN</span
                                                        >
                                                        -->
                                                    </div>
                                                    <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Ciudad:
                                                        <span class="text-bold">Doral</span>
                                                    </div>
                                                    <div v-else class="text-caption">
                                                        Ciudad:
                                                        <span class="text-bold">Doral</span>
                                                    </div>
                                                    <div class="text-caption">
                                                        Estado:
                                                        <span class="text-bold">Florida</span>
                                                    </div>
                                                    <div class="text-caption">
                                                        País:
                                                        <span class="text-bold"
                                                            >Estados Unidos</span
                                                        >
                                                    </div>
                                                    <div  v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Código postal:
                                                        <span class="text-bold">33166-5461</span>
                                                    </div>
                                                    <div v-else class="text-caption">
                                                        Código postal:
                                                        <span class="text-bold">33166</span>
                                                    </div>
                                                    <div v-if="userInformation.user.absorbedCompany == 'LifeLine'" class="text-caption">
                                                        Número de telefono:
                                                        <span class="text-bold">305-592-4534</span>
                                                    </div>
                                                    <div v-else class="text-caption">
                                                        Número de telefono:
                                                        <span class="text-bold">305-592-4534</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mobile-only q-pa-md"></div>
                                        <template v-if="!isBusinessAccount()">
                                            <div
                                                class="
                                                    col-lg-4 col-md-4 col-sm-3 col-xs-12
                                                    column
                                                    flex-center
                                                "
                                                v-if="userInformation.user.affiliateCardNo != null"
                                            >
                                                <div
                                                    :class="`text-h5 text-bold ${
                                                        returnTier(value).tierColor
                                                    }`"
                                                >
                                                    {{ returnTier(value).tierName }}
                                                </div>
                                                <q-circular-progress
                                                    show-value
                                                    class="text-white q-ma-md"
                                                    :value="returnWheelProgress(value)"
                                                    :color="returnTier(value).wheelColor"
                                                    center-color="blue-grey-10"
                                                    size="150px"
                                                    :thickness="0.175"
                                                >
                                                    <div
                                                        class="
                                                            text-h4 text-bold
                                                            full-width
                                                            text-center
                                                        "
                                                    >
                                                        {{ value }}
                                                    </div>
                                                    <div class="text-h6 full-width text-center">
                                                        Puntos
                                                    </div>
                                                </q-circular-progress>
                                            </div>
                                            <div
                                                class="
                                                    col-lg-4 col-md-4 col-sm-3 col-xs-12
                                                    column
                                                    flex-center
                                                "
                                                v-else
                                            >
                                                <i
                                                    class="far fa-star fa-3x text-primary q-mb-md"
                                                ></i>
                                                <div class="text-h5 text-bold q-mb-md">
                                                    Programa de lealtad
                                                </div>
                                                <q-btn
                                                    push
                                                    label="Afíliate aqui"
                                                    color="accent"
                                                    size="sm"
                                                    @click="loyaltyAffiliationDialog = true"
                                                />
                                            </div>
                                        </template>
                                    </div>
                                </q-card-section>
                            </q-card>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-xs-12 q-pa-sm">
                                <div
                                    :class="
                                        userInformation.user.affiliateCardNo != null
                                            ? returnTier(value).frame
                                            : ''
                                    "
                                >
                                    <q-list bordered padding class="bg-white y-go-1x">
                                        <q-item-label header class="text-bold">Menú</q-item-label>
                                        <q-separator />
                                        <q-item clickable v-ripple @click="showUserProfile = false">
                                            <q-item-section avatar top>
                                                <q-avatar color="primary" text-color="white">
                                                    <i class="fas fa-box"></i>
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label lines="1">Mis Facturas</q-item-label>
                                            </q-item-section>
                                        </q-item>

                                        <q-item clickable v-ripple @click="showUserProfile = true">
                                            <q-item-section avatar top>
                                                <q-avatar color="primary" text-color="white">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label lines="1"
                                                    >Perfil y direccion de entrega</q-item-label
                                                >
                                                <q-item-label caption
                                                    >Edita tu informacion y direccion de
                                                    entrega</q-item-label
                                                >
                                            </q-item-section>
                                        </q-item>
                                        <q-item
                                            v-if="userInformation.user.role === 'admin'"
                                            clickable
                                            v-ripple
                                            to="/admin"
                                        >
                                            <q-item-section avatar top>
                                                <q-avatar color="primary" text-color="white">
                                                    <i class="fas fa-cogs"></i>
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label lines="1"
                                                    >Panel Administrativo</q-item-label
                                                >
                                            </q-item-section>
                                        </q-item>
                                        <q-item clickable v-ripple>
                                            <q-item-section avatar top>
                                                <q-avatar color="accent" text-color="white">
                                                    <i class="fas fa-sign-out-alt"></i>
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section @click="logout()">
                                                <q-item-label lines="1">Cerrar sesion</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-xs-12 q-pa-sm">
                                <div
                                    :class="
                                        userInformation.user.affiliateCardNo != null
                                            ? `q-mb-xl ${returnTier(value).frame}`
                                            : 'q-mb-xl '
                                    "
                                >
                                    <UserHome
                                        v-if="!showUserProfile"
                                        :data="userInformation.invoices"
                                        :userData="userInformation.user"
                                    ></UserHome>
                                    <UserProfile
                                        v-else
                                        :userInformationData="userInformation"
                                        :forceUpdateOnUser="needsUpdate"
                                        @saveUserProfile="updateUserProfile"
                                    ></UserProfile>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col desktop-only"></div>
                </div>
            </q-page>
        </q-page-container>
        <div v-if="userInformation.user.absorbedCompany != 'LifeLine'" >
        <Footer />
        </div>
        <q-dialog v-model="loyaltyAffiliationDialog">
            <q-card class="y-go-font">
                <q-form @submit="sendAffiliatedNo">
                    <q-card-section>
                        <div class="text-h6 text-center text-bold">
                            Afíliate a nuestro programa de lealtad!
                        </div>
                    </q-card-section>
                    <q-card-section>
                        <div class="text-subtitle1 text-center">
                            Solo debes solicitar la tarjeta en su sucursal mas cercana, activarla
                            con el numero en la cuenta y ya esta participando.
                        </div>
                    </q-card-section>
                    <q-card-section>
                        <q-input
                            filled
                            type="number"
                            label="Registra tu tarjeta "
                            color="primary"
                            v-model="affiliatedNo"
                            :rules="[val => val.length > 0 || 'El campo es obligatorio']"
                        />
                    </q-card-section>
                    <q-card-actions>
                        <q-btn
                            color="accent"
                            push
                            class="full-width text-bold"
                            type="submit"
                            :disable="displayLoadingForAffiliated"
                        >
                            <span v-if="!displayLoadingForAffiliated">Registrar</span>
                            <q-spinner-facebook
                                v-if="displayLoadingForAffiliated"
                                color="white"
                                size="1em"
                        /></q-btn>
                    </q-card-actions>
                </q-form>
            </q-card>
        </q-dialog>
        <q-dialog
            v-model="completeRegistrationDialog"
            persistent
            maximized
            transition-show="slide-down"
            transition-hide="slide-up"
        >
            <CompleteRegistrationForm
                @close-registration-dialog="closeRegistrationDialogAndRefreshContent()"
                :userData="user"
            />
        </q-dialog>
    </q-layout>
</template>

<script>
//import * as EmailTest from '/functions/lib/emailHandler'
import SocialBar from '@/components/general/SocialBar'

import Navbar from '@/components/general/Navbar'
import Footer from '@/components/general/Footer'
import UserHome from '@/views/Landing/UserHome'
import UserProfile from '@/views/Landing/UserProfile'
import CompleteRegistrationForm from '@/components/UsersDashboard/CompleteRegistrationForm'
import * as api from '@/api/api'
import firebase from 'firebase/app'
import 'firebase/auth'



export default {
    components: {
        SocialBar,
        Navbar,
        Footer,
        UserHome,
        UserProfile,
        CompleteRegistrationForm,
    },
    data() {
        return {
            displayLoading: false,
            confirmationDialog: false,
            displayAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertType: '',
            userInformation: '',
            showUserProfile: false,
            userName: '',
            needsUpdate: false,
            loyaltyAffiliationDialog: false,
            allAffiliatedNo: [],
            value: 0,
            affiliatedNo: '',
            showAffiliateError: false,
            displayLoadingForAffiliated: false,
            completeRegistrationDialog: false,
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        uid() {
            return this.$store.getters.uid
        },
    },
    methods: {

/*
        async sendgridExample() {
            sgMail.setApiKey(functions.config().emailservice.key)
            await sendgrid.send({
            to: 'alonsoapplewhite@yahoo.com',
            from: 'alonso@centracomsa.com',
            subject: 'Sendgrid test email from Node.js on Google Cloud Platform',
            text:
            'Well hello! This is a Sendgrid test email from Node.js on Google Cloud Platform.',
            });
            console.log('email sent');
        },
*/

        isInArray(value, array) {
            return array.indexOf(value) > -1
        },
        showBusinessNotApproved() {
            return this.isBusinessAccount() && !this.userInformation.user.businessAproved
        },
        isBusinessAccount() {
            let seaRate = this.userInformation.user.seaRate
            let airRate = this.userInformation.user.airRate
            if (!seaRate || !airRate) return false
            return seaRate.isBusiness || airRate.isBusiness
        },
        async sendAffiliatedNo() {
            this.displayLoadingForAffiliated = true
            this.displayAlert = false
            if (!this.isInArray(parseInt(this.affiliatedNo), this.allAffiliatedNo)) {
                this.displayLoadingForAffiliated = false
                this.alertTitle = 'Error'
                this.alertMessage = 'Hubo un error con la solicitud por favor inténtelo más tarde'
                this.alertType = 'error'
                this.displayAlert = true
                return
            }
            this.userInformation.user.affiliateCardNo = parseInt(this.affiliatedNo)
            this.userInformation.user.poundsCount = 0
            this.userInformation.user.affliatedDate = Date.now()
            this.userInformation.user.airRate = this.userInformation.user.airRate.id
            this.userInformation.user.seaRate = this.userInformation.user.seaRate.id
            this.value = 0
            try {
                let response = await api.UpdateUserInformationById({
                    uid: this.uid,
                    user: this.userInformation.user,
                })
                if (response.statusText === 'OK') {
                    await api.DeleteAffiliateCardNo({
                        number: this.userInformation.user.affiliateCardNo,
                    })
                    let userProfile = await api
                        .returnUserProfileInformation({uid: this.uid})
                        .then(resp => resp.data.data)
                    await this.$store.commit('SET_USER', userProfile.user)
                    this.userInformation = userProfile
                    this.displayLoadingForAffiliated = false
                    this.loyaltyAffiliationDialog = false
                    this.alertTitle = 'Exito!'
                    this.alertMessage = 'Se ha actualizado con exito la informacion'
                    this.alertType = 'success'
                    this.displayAlert = true
                } else {
                    throw new Error(response)
                }
            } catch (error) {
                console.log(error)
                this.displayLoadingForAffiliated = false
                this.alertTitle = 'Error'
                this.alertMessage = 'Hubo un error con la solicitud por favor inténtelo más tarde'
                this.alertType = 'error'
                this.displayAlert = true
            }

            // api.UpdateUserInformationById({
            //     uid: this.uid,
            //     user: this.userInformation.user,
            // })
            //     .then(async response => {
            //         this.displayLoadingForAffiliated = false
            //         this.loyaltyAffiliationDialog = false
            //         this.alertTitle = 'Exito!'
            //         this.alertMessage = 'Se ha actualizado con exito la informacion'
            //         this.alertType = 'success'
            //         this.displayAlert = true
            //         let userProfile = await api
            //             .returnUserProfileInformation({uid: this.uid})
            //             .then(resp => resp.data.data)
            //         await this.$store.commit('SET_USER', userProfile.user)
            //         // api.getUserInformationById({
            //         //     uid: this.uid,
            //         // }).then(response => {
            //         //     this.$store.commit('SET_USER', response.data.data)
            //         // })
            //     })
            //     .then(() => {
            //         api.DeleteAffiliateCardNo({number: this.userInformation.user.affiliateCardNo})
            //     })
            //     .catch(error => {
            //         console.log(error)
            //         this.displayLoadingForAffiliated = false
            //         this.alertTitle = 'Error'
            //         this.alertMessage =
            //             'Hubo un error con la solicitud por favor inténtelo más tarde'
            //         this.alertType = 'error'
            //         this.displayAlert = true
            //     })
        },
        copyAddressToClipboard(type) {
            let text = ''
            if (type == 'air'){
                if (this.userInformation.user.absorbedCompany == 'LifeLine' && this.userInformation.user.absorbedCompany != null ){
                    text = `8458 NW 70th ST, LQ${this.userInformation.user.box.slice(3)}, Miami, Florida, Estados Unidos, 33166-4941, 305-406-0003`;
                }else{
                    text = `5401 NW 72nd Ave, ${this.userInformation.user.box}, Miami, Florida, Estados Unidos, 33166-4941, 305-364-5238`
                }
            }
            else{
                if (this.userInformation.user.absorbedCompany == 'LifeLine' && this.userInformation.user.absorbedCompany != null ){
                    text = `7854 NW 46 th ST, LQ${this.userInformation.user.box.slice(3)}, Doral, Florida, Estados Unidos, 33166-4941, 305-592-4534`;
                }else{
                   text = `5401 NW 72nd Ave, ${this.userInformation.user.box} (OCEAN), Miami, Florida, Estados Unidos, 33166-4941, 305-364-5238`;
                }
            }
            navigator.clipboard.writeText(text).then(
                () => {
                    /*
                    this.displayLoading = false
                    this.alertTitle = 'Dirección copiada'
                    this.alertMessage = `Recuerda poner siempre tu dirección exacta en todas tus páginas web de compras por Internet. Si la página web te sugiere otra dirección, no la aceptes.`
                    this.alertType = 'success'
                    this.displayAlert = true
                    */
                },
                function (err) {
                    console.error('Async: Could not copy text: ', err)
                }
            )
        },
        async closeRegistrationDialogAndRefreshContent() {
            this.$router.go()
        },
        async updateUserProfile() {
            this.displayLoading = true
            this.displayAlert = false
            this.userInformation.user.airRate = this.userInformation.user.airRate.id
            this.userInformation.user.seaRate = this.userInformation.user.seaRate.id
            api.UpdateUserInformationById({
                uid: this.uid,
                user: this.userInformation.user,
            })
                .then(async response => {
                    this.displayLoading = false
                    this.alertTitle = 'Exito!'
                    this.alertMessage = 'Se ha actualizado con exito la informacion'
                    this.alertType = 'success'
                    this.displayAlert = true
                    let userProfile = await api
                        .returnUserProfileInformation({uid: this.uid})
                        .then(resp => resp.data.data)
                    await this.$store.commit('SET_USER', userProfile.user)
                    this.userInformation = userProfile
                })
                .catch(error => {
                    console.log(error)
                    this.displayLoading = false
                    this.alertTitle = 'Error'
                    this.alertMessage =
                        'Hubo un error con la solicitud por favor inténtelo más tarde'
                    this.alertType = 'error'
                    this.displayAlert = true
                })
        },
        async logout() {
            firebase
                .auth()
                .signOut()
                .then(async () => {
                    await this.$store.dispatch('UserLogout')
                    if (this.userInformation.user.absorbedCompany == 'LifeLine' && this.userInformation.user.absorbedCompany != null ){
                        this.$router.push('/?page=life')
                    } else{
                        this.$router.push('/')
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        returnWheelProgress(pts) {
            if (pts < 200) return (pts / 200) * 100
            if (pts >= 200 && pts < 400) return ((pts - 200) / 200) * 100
            if (pts >= 400 && pts < 600) return ((pts - 400) / 200) * 100
            if (pts >= 600 && pts < 800) return ((pts - 600) / 200) * 100
            if (pts >= 800 && pts < 1000) return ((pts - 800) / 200) * 100
        },
        returnTier(pts) {
            if (pts < 200)
                return {
                    tierName: 'BRONZE',
                    tierColor: 'text-bronze',
                    frame: 'bronze-frame',
                    wheelColor: 'bronze',
                }
            if (pts >= 200 && pts < 400)
                return {
                    tierName: 'SILVER',
                    tierColor: 'text-silver',
                    frame: 'silver-frame',
                    wheelColor: 'silver',
                }
            if (pts >= 400 && pts < 600)
                return {
                    tierName: 'GOLD',
                    tierColor: 'text-gold',
                    frame: 'gold-frame',
                    wheelColor: 'gold',
                }
            if (pts >= 600 && pts < 800)
                return {
                    tierName: 'PLATINUM',
                    tierColor: 'text-platinum',
                    frame: 'platinum-frame',
                    wheelColor: 'platinum',
                }
            if (pts >= 800 && pts < 1000)
                return {
                    tierName: 'DIAMOND',
                    tierColor: 'text-diamond',
                    frame: 'diamond-frame',
                    wheelColor: 'diamond',
                }
        },
        
    },

    async mounted() {
        this.displayLoading = true
        this.userInformation = await api
            .returnUserProfileInformation({uid: this.uid})
            .then(resp => resp.data.data)
        await this.$store.commit('SET_USER', this.userInformation.user)
        this.userName = `${this.userInformation.user.name} ${this.userInformation.user.lastName}`
        this.value = this.userInformation.user.poundsCount
        if (!this.userInformation.user.isUpdated) {


                this.displayLoading = false
                /*
                this.alertTitle = '¡Bienvenido!'
                this.alertMessage ='Debes terminar tu registro, de lo contrario no tendras tu casillero'
                this.alertType = 'success'
                this.displayAlert = true
                */
               // se debe comentar completeRegistratrion para que funcione el ALERT bien
            
            this.completeRegistrationDialog = true
            return
        }
        if (this.userInformation.user.affiliateCardNo == null) {
            await api
                .ReturnAllAffiliateCardNo()
                .then(response => (this.allAffiliatedNo = response.data.data))
        }
        await this.$store.commit(
            'SET_CART',
            this.userInformation.invoices.filter(inv => inv.status === 'unpaid')
        )
        this.displayLoading = false
       // EmailTest.sendgridExample()
   
              
    },

    
}
</script>

<style>
.bronze-frame {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #f5c987, #b68d4c);
}

.text-bronze {
    color: #d0a665;
}
.bg-bronze {
    background: #d0a665;
}
.silver-frame {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #c0c0c0, #737373);
}

.text-silver {
    color: #c0c0c0;
}
.bg-silver {
    background: #c0c0c0;
}

.gold-frame {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #ffd700, #b49800);
}

.text-gold {
    color: #ffd700;
}
.bg-gold {
    background: #ffd700;
}

.platinum-frame {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #e5e4e2, #a8a8a8);
}

.text-platinum {
    color: #a8a8a8;
}
.bg-platinum {
    background: #a8a8a8;
}

.diamond-frame {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #b9f2ff, #22c5eb);
}

.text-diamond {
    color: #22c5eb;
}
.bg-diamond {
    background: #22c5eb;
}
</style>
