<template>
    <q-page class="q-pa-lg">
        <loading-alert :display="displayLoading"></loading-alert>
        <ygo-alert
            :display="displayAlert"
            :title="alertTitle"
            :message="alertMessage"
            :type="alertType"
            @accept="displayAlert = false"
        ></ygo-alert>
        <div v-if="Object.keys(data).length !== 0">
            <div class="row q-mb-lg">
                <div class="text-h5">
                    <div class="col q-px-md">
                        {{ `${data.box} - ${data.name} ${data.lastName}` }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 q-px-md">
                    <q-card class="full-width">
                        <q-card-section>
                            <div class="text-h6">
                                Datos generales
                                <q-btn
                                    color="primary"
                                    :label="editInformation ? 'Guardar' : 'Editar'"
                                    @click="handleData()"
                                    flat
                                />
                            </div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section>
                            <q-input
                                filled
                                label="Nombre"
                                v-model="data.name"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Apellido"
                                v-model="data.lastName"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Correo"
                                v-model="data.email"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <div class="row q-mb-md">
                                <div class="col-3 q-pr-sm">
                                    <q-input
                                        label="Código de area"
                                        filled
                                        v-model="data.countryCode"
                                        :disable="!editInformation"
                                    />
                                </div>
                                <div class="col q-pl-sm">
                                    <q-input
                                        filled
                                        label="Telefono"
                                        v-model="data.phone"
                                        :disable="!editInformation"
                                    />
                                </div>
                            </div>
                            <q-input
                                filled
                                label="Numero de Affiliado"
                                v-model.number="data.affiliateCardNo"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <q-select
                                filled
                                v-model="currentlySelectedRole"
                                :disable="!editInformation"
                                :options="['Usuario', 'Administrador']"
                                label="Acceso"
                                class="q-mb-md"
                            />
                            <q-select
                                filled
                                v-model="selectedSubsidiary"
                                :disable="!editInformation"
                                :options="[
                                    {value: 0, label: 'Panamá'},
                                    {value: 1, label: 'Penonome'},
                                    {value: 2, label: 'David'},
                                ]"
                                emit-value
                                map-options
                                label="Sucursal"
                                class="q-mb-md"
                            />
                            <q-select
                                filled
                                v-model="currentPlan"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                :options="returnCurrentPlan()"
                                class="q-mb-md"
                                label="Plan Actual"
                                map-options
                                emit-value
                                :disable="!editInformation"
                            >
                            </q-select>
                            <q-select
                                filled
                                v-model="data.airRate"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                :options="returnOptions(filteredAirRates)"
                                class="q-mb-md"
                                label="Tarifa aerea"
                                map-options
                                emit-value
                                :disable="!editInformation"
                            >
                            </q-select>
                            <q-select
                                filled
                                v-model="data.seaRate"
                                use-input
                                hide-selected
                                fill-input
                                input-debounce="0"
                                :options="returnOptions(filteredSeaRates)"
                                map-options
                                emit-value
                                class="q-mb-md"
                                label="Tarifa maritima"
                                :disable="!editInformation"
                            >
                            </q-select>
                            <q-select
                                filled
                                v-model="data.absorbedCompany"
                                :disable="!editInformation"
                                :options="['Ernel','LifeLine']"
                                label="Compañia absorbida"
                                class="q-mb-md"
                            />
                            <div v-if="checkIfRateIsBusiness() && !data.businessAproved">
                                <div class="text-caption q-mb-sm">Solicitud Plan Business</div>
                                <q-btn
                                    label="Aprobar"
                                    color="primary"
                                    outline
                                    size="sm"
                                    class="on-left"
                                    @click="approveBusinessRequest()"
                                />
                                <q-btn
                                    label="Rechazar"
                                    color="red-7"
                                    outline
                                    size="sm"
                                    @click="rejectBusinessRequest()"
                                />
                            </div>
                        </q-card-section>
                        <q-card-section>
                            <div class="text-h6">Direccion 1</div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section>
                            <q-input
                                filled
                                label="Distrito"
                                v-model="data.district"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Direccion"
                                v-model="data.address"
                                :disable="!editInformation"
                                type="textarea"
                                rows="3"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Extra"
                                v-model="data.addressExtra"
                                :disable="!editInformation"
                                type="textarea"
                                rows="3"
                                class="q-mb-md"
                            />
                        </q-card-section>
                        <q-card-section>
                            <div class="text-h6">Direccion 2</div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section>
                            <q-input
                                filled
                                label="Distrito"
                                v-model="data.district2"
                                :disable="!editInformation"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Direccion"
                                v-model="data.address2"
                                :disable="!editInformation"
                                type="textarea"
                                rows="3"
                                class="q-mb-md"
                            />
                            <q-input
                                filled
                                label="Extra"
                                v-model="data.addressExtra2"
                                :disable="!editInformation"
                                type="textarea"
                                rows="3"
                                class="q-mb-md"
                            />
                        </q-card-section>
                        <q-separator />
                        <q-card-actions>
                            <q-space />
                            <q-btn
                                color="primary"
                                :label="editInformation ? 'Guardar' : 'Editar'"
                                @click="handleData()"
                                flat
                            />
                        </q-card-actions>
                    </q-card>
                </div>
                <div class="col-lg-6 q-px-md">
                    <q-table
                        :data="allUserInvoices"
                        :columns="invoicesColumns"
                        row-key="name"
                        :pagination.sync="initialPagination"
                        class="full-width"
                        :title="`Facturas ${
                            userAmountDue === 0
                                ? ''
                                : `Saldo Pendiente: $${parseFloat(userAmountDue).toFixed(2)}`
                        }`"
                    >
                        <template v-slot:header="props">
                            <q-tr :props="props">
                                <q-th v-for="col in props.cols" :key="col.name" :props="props">{{
                                    col.label
                                }}</q-th>
                            </q-tr>
                        </template>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <!-- <q-td v-for="col in props.cols" :key="col.name" :props="props">{{
                                    col.value
                                }}</q-td> -->
                                <q-td key="invoice" :props="props">
                                    <router-link
                                        :to="`/invoice-details/${props.row.id}`"
                                        class="text-primary"
                                    >
                                        {{ props.row.invoice }}</router-link
                                    >
                                </q-td>
                                <q-td key="amount" :props="props"> {{ props.row.amount }} </q-td>
                                <q-td key="packages" :props="props">
                                    {{ props.row.packages }}
                                </q-td>
                                <q-td key="date" :props="props"> {{ props.row.date }} </q-td>
                                <q-td key="status" :props="props">
                                    <q-btn
                                        :color="returnStatus(props.row.status).color"
                                        class="text-white"
                                        rounded
                                        size="sm"
                                    >
                                        <i
                                            :class="`fas fa-${
                                                returnStatus(props.row.status).icon
                                            } q-mr-sm`"
                                        ></i>
                                        {{ returnStatus(props.row.status).status }}
                                    </q-btn>
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
            </div>
        </div>
    </q-page>
</template>

<script>
import * as api from '@/api/api'
import dates from '@/mixins/dates'
export default {
    mixins: [dates],
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    data() {
        return {
            displayLoading: false,
            displayAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertType: '',
            role: '',
            allRates: [],

            currentlySelectedRole: '',
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },
            data: [],
            editInformation: false,
            invoicesColumns: [
                {
                    name: 'invoice',
                    align: 'left',
                    label: 'Factura',
                    field: 'invoice',
                    sortable: true,
                },
                {
                    name: 'amount',
                    align: 'left',
                    label: 'Monto ($)',
                    field: 'amount',
                    sortable: true,
                },
                {
                    name: 'packages',
                    align: 'left',
                    label: 'Paquetes (cant.)',
                    field: 'packages',
                    sortable: true,
                },
                {
                    name: 'date',
                    align: 'left',
                    label: 'Facturado el',
                    field: 'date',
                    sortable: true,
                },
                {
                    name: 'status',
                    align: 'left',
                    label: 'Status',
                    field: 'status',
                    sortable: true,
                },
            ],
            filteredSeaRates: [],
            filteredAirRates: [],
            selectedSubsidiary: '',
            currentPlan: '',
            selectedCurrentPlan: '',
            allUserInvoices: [],
            userAmountDue: 0,
        }
    },
    watch: {
        selectedSubsidiary(newValue, oldValue) {
            this.selectedSubsidiary = newValue
            this.data.subsidiary = newValue
            this.filterRatesAcordingToSubsidiary()
        },
        currentPlan(newValue, oldValue) {
            this.selectedCurrentPlan = newValue
            this.filterRatesAcordingToSubsidiary()
        },
    },
    methods: {
        determineCurrentPlan() {
            let currentPlans = [
                'PLAN BASICO',
                'PLAN CERO VOLUMEN',
                'PLAN F&F',
                'PLAN MARKETING',
                'PLAN BUSINESS',
            ]
            let airRate = this.allRates.find(rate => rate.id === this.data.airRate)
            for (const planName of currentPlans) {
                if (airRate.name.includes(planName)) {
                    this.currentPlan = planName
                    break
                }
            }
        },
        returnCurrentPlan() {
            let currentPlans = [
                'PLAN BASICO',
                'PLAN CERO VOLUMEN',
                'PLAN F&F',
                'PLAN MARKETING',
                'PLAN BUSINESS',
            ]
            return currentPlans.map(plan => ({
                value: plan,
                label: plan,
            }))
        },
        checkIfRateIsBusiness() {
            let seaRate = this.allRates.find(rate => rate.id === this.data.seaRate)
            let airRate = this.allRates.find(rate => rate.id === this.data.airRate)
            if (!seaRate || !airRate) return false
            return seaRate.isBusiness || airRate.isBusiness
        },
        returnRateName(id) {
            return this.allRates.find(rate => rate.id === id)?.name || 'N/A'
        },
        returnOptions(rates) {
            return rates.map(rate => ({
                value: rate.id,
                label: `${rate.name} | ${
                    rate.subsidiary === 0 ? 'Panama' : rate.subsidiary === 1 ? 'Penonome' : 'David'
                } | $${rate.rate.toFixed(2)}`,
            }))
        },
        async filterRatesAcordingToSubsidiary() {
            this.selectedSubsidiary = this.data.subsidiary
            let rates = await this.allRates
            this.filteredAirRates = rates.filter(
                rate =>
                    rate.type === 'air' &&
                    rate.subsidiary === this.selectedSubsidiary &&
                    rate.name.includes(this.currentPlan)
            )
            this.filteredSeaRates = rates.filter(
                rate =>
                    rate.type === 'sea' &&
                    rate.subsidiary === this.selectedSubsidiary &&
                    rate.name.includes(this.currentPlan)
            )
        },
        rejectBusinessRequest() {
            this.displayLoading = true
            this.displayAlert = false
            this.data.seaRate = this.allRates.find(
                rate =>
                    rate.name === 'Plan Basico' &&
                    rate.subsidiary === this.data.subsidiary &&
                    rate.type === 'sea'
            ).id
            this.data.airRate = this.allRates.find(
                rate =>
                    rate.name === 'Plan Basico' &&
                    rate.subsidiary === this.data.subsidiary &&
                    rate.type === 'air'
            ).id
            api.UpdateUserInformationById({uid: this.$route.params.id, user: this.data})
                .then(response => {
                    this.displayLoading = false
                    this.data.businessAproved = true
                    this.alertTitle = 'Exito!'
                    this.alertMessage = 'Se ha cancelado con exito la peticion'
                    this.alertType = 'success'
                    this.displayAlert = true
                })
                .catch(error => {
                    console.log(error)
                    this.displayLoading = false
                    this.alertTitle = 'Error'
                    this.alertMessage =
                        'Hubo un error con la solicitud por favor inténtelo más tarde'
                    this.alertType = 'error'
                    this.displayAlert = true
                })
        },
        approveBusinessRequest() {
            this.displayLoading = true
            this.displayAlert = false
            api.ApproveBusinessRequest({uid: this.$route.params.id})
                .then(response => {
                    this.displayLoading = false
                    this.data.businessAproved = true
                    this.alertTitle = 'Exito!'
                    this.alertMessage = 'Se ha aprobado con exito la peticion'
                    this.alertType = 'success'
                    this.displayAlert = true
                })
                .catch(error => {
                    console.log(error)
                    this.displayLoading = false
                    this.alertTitle = 'Error'
                    this.alertMessage =
                        'Hubo un error con la solicitud por favor inténtelo más tarde'
                    this.alertType = 'error'
                    this.displayAlert = true
                })
        },
        returnStatus(status) {
            if (status == 'unpaid') return {status: 'Pendiente', color: 'red', icon: 'dollar-sign'}
            if (status == 'paid') return {status: 'Pagado', color: 'green', icon: 'dollar-sign'}
            if (status == 'review')
                return {status: 'En Proceso', color: 'amber', icon: 'dollar-sign'}
            if (status == 'delivered') return {status: 'Entregado', color: 'primary', icon: 'box'}
        },
        handleData() {
            //Si editGeneralInfo es falso ponlo true y ya.
            if (!this.editInformation) {
                this.editInformation = true
                return
            }
            this.editInformation = false
            this.update()
        },
        async update() {
            let obj = this.data
            this.displayLoading = true
            this.displayAlert = false
            this.currentlySelectedRole === 'Administrador'
                ? (obj.role = 'admin')
                : (obj.role = 'user')
            // obj.rate = this.rates.find(rate => rate.name === this.currentlySelectedRate).id
            // if (this.currentlySelectedRate === 'Plan Business') obj.businessAproved = true
            let seaRate = this.allRates.find(rate => rate.id === this.data.seaRate)
            let airRate = this.allRates.find(rate => rate.id === this.data.airRate)
            if (seaRate.subsidiary !== obj.subsidiary || airRate.subsidiary !== obj.subsidiary) {
                this.displayLoading = false
                this.alertTitle = 'Error'
                this.alertMessage =
                    'Por favor revisa las subsidiaria con los planes seleccionados no concuerdan.'
                this.alertType = 'error'
                this.displayAlert = true
                return
            }
            if (
                !seaRate.name.includes(this.currentPlan) ||
                !airRate.name.includes(this.currentPlan)
            ) {
                this.displayLoading = false
                this.alertTitle = 'Error'
                this.alertMessage = 'Por favor revisa las tarifas, ya que no concuerdan'
                this.alertType = 'error'
                this.displayAlert = true
                return
            }
            api.UpdateUserInformationById({
                uid: this.$route.params.id,
                user: obj,
            })
                .then(response => {
                    this.displayLoading = false
                    this.alertTitle = 'Exito!'
                    this.alertMessage = 'Se ha actualizado con exito la informacion'
                    this.alertType = 'success'
                    this.displayAlert = true
                })
                .catch(error => {
                    console.log(error)
                    this.displayLoading = false
                    this.alertTitle = 'Error'
                    this.alertMessage =
                        'Hubo un error con la solicitud por favor inténtelo más tarde'
                    this.alertType = 'error'
                    this.displayAlert = true
                })
        },
    },
    async mounted() {
        this.displayLoading = true
        this.data = await api
            .getUserInformationById({uid: this.$route.params.id})
            .then(resp => resp.data.data)
        this.allUserInvoices = await api
            .ReturnAllUserInvocies({uid: this.$route.params.id})
            .then(resp => resp.data.data)
            .then(data =>
                data.sort((a, b) => this.sortDate(a.creationTime, b.creationTime, 'desc'))
            )
            .then(data => {
                for (const invoice of data) {
                    if (invoice.status === 'unpaid' || invoice.status === 'review') {
                        this.userAmountDue += parseFloat(invoice.price)
                    }
                }
                return data
            })
            .then(data =>
                data.map(invoice => ({
                    id: invoice.id,
                    invoice: invoice.No,
                    amount: `$ ${invoice.price}`,
                    packages: Object.keys(invoice.packages).length,
                    date: this.returnFormattedDate(invoice.creationTime),
                    status: invoice.status,
                }))
            )
        this.allRates = await api.ReturnAllRates().then(resp => resp.data.data)
        this.determineCurrentPlan()
        await this.filterRatesAcordingToSubsidiary()
        this.currentlySelectedRole = this.data.role === 'admin' ? 'Administrador' : 'Usuario'
        this.displayLoading = false
    },
}
</script>
